import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
import UserResetPasswordConfirmed from '../components/user/user-reset-password-confirmed';
import locale from '../locale/locale';

const CustomerChangePasswordPage = () => {
  useAuthUserCheckOrError();
  const pageData = {
    ...locale.changePasswordPage,
  };
  return (
    <Layout>
      <SEO title="Account Change Password" />
      <UserResetPasswordConfirmed
        type={'update'}
        pageData={pageData}
      />
    </Layout>
  );
};

export default CustomerChangePasswordPage;
